import { useMemo } from 'react';
import { useGetAllAsset, OrderInfoResponse, OrderSide, OrderStatus, OrderType } from '@ping/api';
import { EOrderSide, EOrderStatus } from '@ping/enums';
import { parsePair, t } from '@ping/helpers';
import { StatusIndicator, Text } from '@ping/uikit';
import { format, putSpaceBetweenWords } from '@ping/utils';
import { selectedLanguageSelector, usePreferencesStore } from '@ping/stores/preferences.store';

interface IExtraColumns extends ICustomColumnProperties {
  Header: string | JSX.Element;
  accessor: string;
  Cell?: (any) => JSX.Element;
}

const getOrdersColumnsList = (
  extraColumns?: IExtraColumns,
  dependencies?: any[],
  isSortedByUpdatedAt?: boolean,
  isDateFirstColumn?: boolean
) => {
  const selectedLanguage = usePreferencesStore(selectedLanguageSelector);
  const { data: allAssets } = useGetAllAsset();

  const assetScaleMap = new Map(allAssets?.data.map(item => [item.id, item.scale]));

  return useMemo(
    () => [
      ...(isDateFirstColumn
        ? [
            {
              Header: t('Date & Time'),
              accessor: isSortedByUpdatedAt ? 'updatedAt' : 'createdAt',
              sticky: 'left',
              // More space is needed in this column for the "September" word
              width: 240,
              Cell: ({ value }: { value: string }) => <div>{format.date(value)}</div>,
            },
          ]
        : []),
      {
        Header: t('Pair'),
        accessor: 'instrument',
        sticky: !isDateFirstColumn && 'left',
        width: 100,
        Cell: ({ value }: { value: string }) => parsePair.byInstrument(value).market,
      },
      {
        Header: t('Type of order'),
        accessor: 'orderType',
        width: 110,
        Cell: ({ value }: { value: OrderType }) => <div>{putSpaceBetweenWords(value)}</div>,
      },
      {
        Header: t('Amount'),
        accessor: 'amount',
        Cell: ({ row: { original } }: { row: { original: OrderInfoResponse } }) => {
          const cryptoCurrencyScale = assetScaleMap.get(parsePair.byInstrument(original.instrument).base.toLowerCase());

          return (
            <>
              {format.number(original.amount.toFixed(cryptoCurrencyScale))}
              &nbsp;
              <Text badge cadetBlueColor>
                {parsePair.byInstrument(original.instrument).base}
              </Text>
            </>
          );
        },
      },
      {
        Header: t('Side'),
        accessor: 'side',
        width: 50,
        Cell: ({ value }: { value: OrderSide }) => (
          <span className={`status--${value === EOrderSide.BUY ? 'green' : 'red'}`}>{value}</span>
        ),
      },
      {
        Header: t('Price'),
        accessor: 'price',
        Cell: ({ row: { original } }: { row: { original: OrderInfoResponse } }) => {
          const cryptoCurrencyScale = assetScaleMap.get(
            parsePair.byInstrument(original.instrument).quote.toLowerCase()
          );

          return (
            <>
              {format.number(original.price.toFixed(cryptoCurrencyScale))}
              &nbsp;
              <Text badge cadetBlueColor>
                {parsePair.byInstrument(original.instrument).quote}
              </Text>
            </>
          );
        },
      },
      {
        Header: t('Filled'),
        accessor: 'unitsFilled',
        Cell: ({ row: { original } }: { row: { original: OrderInfoResponse } }) => {
          const cryptoCurrencyScale = assetScaleMap.get(parsePair.byInstrument(original.instrument).base.toLowerCase());

          return (
            <>
              {format.number(original.unitsFilled.toFixed(cryptoCurrencyScale))}
              &nbsp;
              <Text badge cadetBlueColor>
                {parsePair.byInstrument(original.instrument).base}
              </Text>
            </>
          );
        },
      },
      {
        Header: t('Not filled'),
        accessor: 'remaining',
        Cell: ({ row: { original } }: { row: { original: OrderInfoResponse } }) => {
          const cryptoCurrencyScale = assetScaleMap.get(parsePair.byInstrument(original.instrument).base.toLowerCase());

          return (
            <>
              {format.number(original.remaining.toFixed(cryptoCurrencyScale))}
              &nbsp;
              <Text badge cadetBlueColor>
                {parsePair.byInstrument(original.instrument).base}
              </Text>
            </>
          );
        },
      },
      {
        Header: t('Triggering conditions'),
        accessor: 'executionPrice',
        width: 160,
        Cell: ({ row: { original } }: { row: { original: OrderInfoResponse } }) => {
          const cryptoCurrencyScale = assetScaleMap.get(
            parsePair.byInstrument(original.instrument).quote.toLowerCase()
          );

          return (
            <>
              =&gt; &nbsp;
              {format.number(original.stopPrice.toFixed(cryptoCurrencyScale))}
              &nbsp;
              <Text badge cadetBlueColor>
                {parsePair.byInstrument(original.instrument).quote}
              </Text>
            </>
          );
        },
      },
      {
        Header: t('Fee'),
        accessor: 'commission',
        Cell: ({ row: { original } }: { row: { original: OrderInfoResponse } }) => {
          const cryptoCurrencyScale = assetScaleMap.get(
            original.type === 'buy'
              ? parsePair.byInstrument(original.instrument).base.toLocaleLowerCase()
              : parsePair.byInstrument(original.instrument).quote.toLocaleLowerCase()
          );

          return (
            <>
              {format.number(original.commission.toFixed(cryptoCurrencyScale))}
              &nbsp;
              <Text badge cadetBlueColor>
                {original.type === 'buy'
                  ? parsePair.byInstrument(original.instrument).base
                  : parsePair.byInstrument(original.instrument).quote}
              </Text>
            </>
          );
        },
      },
      {
        Header: t('Total'),
        accessor: 'total',
        Cell: ({ row: { original } }: { row: { original: OrderInfoResponse } }) => {
          const cryptoCurrencyScale = assetScaleMap.get(
            parsePair.byInstrument(original.instrument).quote.toLowerCase()
          );

          return (
            <>
              {format.number(original.total.toFixed(cryptoCurrencyScale))}
              &nbsp;
              <Text badge cadetBlueColor>
                {parsePair.byInstrument(original.instrument).quote}
              </Text>
            </>
          );
        },
      },
      {
        Header: t('Status'),
        accessor: 'status',
        width: 115,
        Cell: ({ value, row: { original } }: { value: OrderStatus; row: { original: OrderInfoResponse } }) => (
          <StatusIndicator
            status={value}
            hint={original.cancelReason}
            green={EOrderStatus.COMPLETED}
            red={[EOrderStatus.CANCELLED, EOrderStatus.REJECTED, EOrderStatus.EXPIRED]}
            blue={EOrderStatus.WORKING}
            yellow={[EOrderStatus.PENDING, EOrderStatus.SENDING]}
            gray={[EOrderStatus.WAITING_FOR_PAYMENT]}
          />
        ),
      },
      ...(!isDateFirstColumn
        ? [
            {
              Header: t('Date & Time'),
              accessor: isSortedByUpdatedAt ? 'updatedAt' : 'createdAt',
              Cell: ({ value }: { value: string }) => format.date(value),
            },
          ]
        : []),
      ...(extraColumns ? [extraColumns] : []),
    ],
    [selectedLanguage, allAssets, ...(dependencies || [])]
  );
};

export default getOrdersColumnsList;
