import { clsx } from 'clsx';
import { useRef } from 'react';

import { useClientEffect } from '@ping/hooks';
import { domEvent, DateTime, wait } from '@ping/utils';
import { mergeProps, useFocusRing, useTabList } from 'react-aria';
import { useTabListState } from 'react-stately';

import { Tab } from './Tab';
import { TabPanel } from './TabPanel';

import type IReactAria from 'react-aria';
import type IReactStately from 'react-stately';

import style from './style.module.scss';

interface ITabsRootProps<T> extends IReactStately.TabListProps<T>, IReactAria.AriaTabListProps<T>, ICustomizable {
  variant: ITabsVariant;
  /**
   * Whether the tab buttons should be evenly spaced or not.
   */
  isEven?: boolean;
  /**
   * Whether to mount the tab panel in the DOM even when it is not currently selected.
   * Inactive tab panels are hidden and cannot be interacted with.
   */
  isMountable?: boolean;
  tabsHeaderClassName?: string;
}

export const TabsRoot = <T extends object>(props: ITabsRootProps<T>) => {
  const state = useTabListState(props);
  const tabButtonsRef = useRef<HTMLDivElement>();
  const { tabListProps } = useTabList(props, state, tabButtonsRef);
  const { focusProps, isFocusVisible } = useFocusRing({ within: true });

  //
  // It sets the dimensions of a selection indicator based on the current selected tab
  const setSelectionIndicatorDimensions: React.EffectCallback = () => {
    if (!tabButtonsRef.current) {
      return;
    }

    const activeTab = tabButtonsRef.current.querySelector<HTMLDivElement>('[role="tab"][aria-selected="true"]');
    if (!activeTab) {
      return;
    }

    tabButtonsRef.current.style.setProperty('--tab-selection-inline-size', activeTab.offsetWidth + 'px');
    tabButtonsRef.current.style.setProperty('--tab-selection-offset-x', activeTab.offsetLeft + 'px');
  };

  //
  // It updates the dimensions of a selection indicator based on the browser's window size
  const updateSelectionIndicatorDimensions: React.EffectCallback = () => {
    return domEvent.attach(window, 'resize', () => {
      setSelectionIndicatorDimensions();
      wait(DateTime.milliseconds(360)).then(setSelectionIndicatorDimensions); // update after CSS animation is finished
    });
  };

  useClientEffect(setSelectionIndicatorDimensions, [state.selectedKey]);
  useClientEffect(updateSelectionIndicatorDimensions, []);

  return (
    <div className={clsx(style['tabs'], props.className)} data-variant={props.variant}>
      <div className={style['tabs__wrapper']}>
        <div
          className={clsx(style['tabs__header'], props.tabsHeaderClassName)}
          data-even={props.isEven}
          {...mergeProps(tabListProps, focusProps)}
          ref={tabButtonsRef}
        >
          <div className={style['tabs__selection']} data-is-focus-visible={isFocusVisible} />

          {[...state.collection].map(item => (
            <Tab
              key={item.key}
              item={item}
              state={state}
              orientation={props.orientation}
              variant={props.variant}
              disabled={item.props.disabled}
              disableMessage={item.props.disabledMessage}
            />
          ))}
        </div>
      </div>

      {!props.isMountable && (
        <TabPanel panelKey={state.selectedItem.key} state={state}>
          {state.selectedItem.props.children}
        </TabPanel>
      )}

      {props.isMountable &&
        [...state.collection].map(panel => (
          <TabPanel key={panel.key} panelKey={panel.key} state={state}>
            {panel.props.children}
          </TabPanel>
        ))}
    </div>
  );
};
